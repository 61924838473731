// const protocol = "http";
// const host = "localhost:8088/api/v1";

const protocol = "https"; //live
const host = "api.leadership.net/api/v1"; // for live

const protocolDev = "http";
const hostDev = "172.190.25.121:8080/api/v1";

// const protocol = "https"; //live
// const host = "api.leadership.rejoicehub.net/api/v1"; // for devlopment

//
const port = "";
const trailUrl = "";

const hostUrl = `${protocol}://${host}${port ? ":" + port : ""}`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}${trailUrl}`;

console.log('URL', hostUrl)

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
