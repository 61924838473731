import React from 'react';

import { leadership, leaderbridge, appliedPsychologists, leadershipLink } from '../../utils/brandNames';

const Leadership = ({
    backgroundColor
}) => {
  return (
    <a 
        className={`navbar-brand logo mb-0 font-w-6 fs-4`}
        href={leadershipLink}
        style={{
            border: `2px solid ${backgroundColor === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.8)'}`,
            color: `${backgroundColor === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.8)'}`,
            padding: `5px 10px`,
            fontFamily: `lustria`
        }}
    >
        {leadership}
    </a>
  )
}

export default Leadership