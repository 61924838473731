import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import moment from "moment";

// import CreateIcon from "@material-ui/icons/Create";
// import DeleteIcon from "@material-ui/icons/Delete";
// import { Button } from "react-bootstrap";
// import { getUserInfo } from "../../../utils/user.util";
// import { Modal } from "react-bootstrap";
// import { Dialog } from "@material-ui/core";
// import { List } from "@material-ui/core";
// import { Toolbar } from "@material-ui/core";
// import { IconButton } from "@material-ui/core";
// import CloseIcon from "@material-ui/icons/Close";
// import { Slide } from "@material-ui/core";
// import { toast } from "react-toastify";
// import Loader from "react-loader-spinner";
// import Profile from "./Profile";
// import ReactTooltip from "react-tooltip";
// import CsvDownload from "react-json-to-csv";\

// Dev imports start...
import {
    ApiDelete,
    ApiGet,
    ApiGetNoAuth,
    ApiPost,
    ApiPut,
} from "../../../helpers/API/ApiData";

import { getTimezoneAdjustedTime } from '../../../utils/getTimezoneOffsetAdjustedTime';
// Dev imports start...

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const WebinarInvitation = (props) => {
    let history = useHistory();

    // State variables start...
    const [webinarInvitationData, setWebinarInvitationData] = useState([]);
    // State variables end...


    const columns = [
        {
            name: "S.No.",
            cell: (row, index) => index + 1,
            width: "5%",
        },
        {
            name: "Date",
            selector: "createdAt",
            cell: (row) => {                                                                            
                return (
                    <div>{moment(getTimezoneAdjustedTime(row?.createdAt)).utc().format("MMM Do YYYY, h:mm a")}</div>
                );
            },
            width: "210px",
            sortable: true,
        },
        {
            name: "Name",
            selector: "name",
            sortable: true,
            width: "300px",
        },
        {
            name: "Email",
            // selector: "email",
            width: "300px",
            cell: (row) => {
                return (
                    <p>{row?.email}</p>
                )
            },
            sortable: true,
        },
        {
            name: "LinkedIn Profile",
            // selector: "linkedInProfile",
            width: "300px",
            cell: (row) => {
                return (
                    <p>{row?.linkedInProfile}</p>
                )
            },
            sortable: true,
        },
    ];

    const customStyles = {
        header: {
        style: {
            minHeight: "56px",
        },
        },
        headRow: {
        style: {
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: defaultThemes.default.divider.default,
        },
        },
        headCells: {
        style: {
            "&:not(:last-of-type)": {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
            },
        },
        },
        cells: {
        style: {
            "&:not(:last-of-type)": {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
            },
        },
        },
    };


    // Effects start...
    useEffect(() => {
        const asyncHandler = async () => {
            const res = await ApiGetNoAuth(`webinar/get-all-invitation`);

            console.log('RESPONSE', res);

            if (res?.status === 200) {
                const data = res?.data?.payload?.data;
                data.sort((a,b) => {
                    if ( a.createdAt < b.createdAt ){
                        return 1;
                    }
                    if ( a.createdAt > b.createdAt ){
                        return -1;
                    }
                    return 0;
                })
                setWebinarInvitationData(data);
            } else {
                toast.error(`${res.message || `Some error occured`}`, { theme: 'colored' });
            }
        }

        asyncHandler();
    }, []);
    // Effects end...

    return (
        <>
            <div>
                <div className="card p-1">
                    <DataTable
                        columns={columns}
                        data={webinarInvitationData}
                        pagination
                        customStyles={customStyles}
                        // conditionalRowStyles={conditionalRowStyles}
                        style={{
                            marginTop: "-3rem",
                        }}
                        // progressPending={isLoaderVisible}
                        // progressComponent={
                        // <Loader type="Puff" color="#334D52" height={30} width={30} />
                        // }
                    />
                </div>
            </div>
        </>
    );
};

export default WebinarInvitation;
