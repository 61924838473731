/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { createContext, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Routes } from "../app/Routes";
// import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";

export const DataLoaded = createContext();

export default function App() {
  const [answer, setAnswer] = useState();
  const [ChatRoomData, setChatRoomData] = useState();
  const [idForAnswer, setIdForAnswer] = useState("");
  const [DateFunctions, setDateFunctions] = useState();

  const store = {
    answerData: [answer, setAnswer],
    RoomData: [ChatRoomData, setChatRoomData],
    DataFun: [DateFunctions, setDateFunctions],
    IdAnswer: [idForAnswer, setIdForAnswer],
  };
  return (
    <React.Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter>
        <MaterialThemeProvider>
          {/* <I18nProvider> */}
          <DataLoaded.Provider value={store}>
            <ToastContainer />
            <Routes />
          </DataLoaded.Provider>
          {/* </I18nProvider> */}
        </MaterialThemeProvider>
      </BrowserRouter>
    </React.Suspense>
  );
}
