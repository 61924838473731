// const protocol = "https";
// const host = "api.leaderbridge.rejoicehub.com/api/v1"; // deployment

const protocol = "https"; //live
const host = "api.leaderbridge.com/api/v1"; // for live

const socketURL = "api.leaderbridge.com/"; // for live
// const socketURL = "api.leaderbridge.rejoicehub.com/";

// const protocol = "http";
// const host = "localhost:8088/api/v1";

const port = "";
const trailUrl = "";

export const socketURl = `${protocol}://${socketURL}`;
const hostUrl = `${protocol}://${host}${port ? ":" + port : ""}/`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}${trailUrl}`;

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
